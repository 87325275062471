import {
  playItemCollectedSound,
  playMoveSound,
  bigSplashSound,
  playGame,
} from '../Assets/Sounds';

const DOWN = 40;
const UP = 38;
const LEFT = 37;
const RIGHT = 39;
const ESC = 27;

class Controller {
  constructor(player, scene, event, game) {
    this.player = player;
    this.scene = scene;
    this.event = event;
    this.game = game;
    const start = {
      x: scene.getStartTile().xPosition,
      y: scene.getStartTile().yPosition,
    };
    this.player.setLocation(start.x, start.y);
    this.scene.setTileContent(start.x, start.y, player.getRenderObject());
    this.initializeListeners();
  }

  resetPlayerStartLocation() {
    const start = {
      x: this.scene.getStartTile().xPosition,
      y: this.scene.getStartTile().yPosition,
    };
    this.player.setLocation(start.x, start.y);
    this.scene.setTileContent(start.x, start.y, this.player.getRenderObject());
  }

  initializeListeners() {
    document.onkeydown = (event) => {
      if (!this.game.state.showModal &&
          !this.game.state.showActionDialog &&
          !this.game.state.showQuestionModal) {
        event.preventDefault();
        event.stopPropagation();
        switch (event.keyCode) {
          case DOWN:
            this.moveDown();
            break;
          case UP:
            this.moveUp();
            break;
          case LEFT:
            this.player.setDirection('LEFT');
            this.moveLeft();
            break;
          case RIGHT:
            this.player.setDirection('RIGHT');
            this.moveRight();
            break;
          case ESC:
            if (this.game.fullscreen) {
              this.game.toggleFullscreen();
            }
            break;
          default:
        }
        this.updateScene();
      }
    };
  }

  handleTouchEvent(direction) {
    switch (direction) {
      case 'top':
        this.moveUp();
        break;
      case 'bottom':
        this.moveDown();
        break;
      case 'left':
        this.player.setDirection('LEFT');
        this.moveLeft();
        break;
      case 'right':
        this.player.setDirection('RIGHT');
        this.moveRight();
        break;
      default:
        break;
    }
    this.updateScene();
  }

  moveLeft() {
    const { x, y } = this.player.location;
    const newLocation = this.scene.getPathTile(x - 1, y);
    if (newLocation) {
      const { xPosition, yPosition } = newLocation;
      playMoveSound();
      this.updatePlayer(xPosition, yPosition);
    }
  }

  moveRight() {
    const { x, y } = this.player.location;
    const newLocation = this.scene.getPathTile(x + 1, y);
    if (newLocation) {
      const { xPosition, yPosition } = newLocation;
      playMoveSound();
      this.updatePlayer(xPosition, yPosition);
    }
  }

  moveUp() {
    const { x, y } = this.player.location;
    const newLocation = this.scene.getPathTile(x, y - 1);
    if (newLocation) {
      const { xPosition, yPosition } = newLocation;
      playMoveSound();
      this.updatePlayer(xPosition, yPosition);
    }
  }

  moveDown() {
    const { x, y } = this.player.location;
    const newLocation = this.scene.getPathTile(x, y + 1);
    if (newLocation) {
      const { xPosition, yPosition } = newLocation;
      playMoveSound();
      this.updatePlayer(xPosition, yPosition);
    }
  }

  updateScene() {
    const { location } = this.player;
    this.scene.setTileContent(location.x, location.y, this.player.getRenderObject());
    this.event();
  }

  updateScoreAndScene(score) {
    const { location } = this.player;
    this.updatePlayerScore(score);
    this.updatePlayerLocation(location.x, location.y);
    this.updateScene();
  }

  updatePlayer(x, y) {
    const { location } = this.player;
    this.clearPreviousLocationTile(location.x, location.y);
    this.checkIfPlayerWillEncounterPortal(x, y);
    this.checkIfPlayerWillEncounterAnEnemy(x, y);
    this.checkIfPlayerWillCollectItem(x, y);
    this.updatePlayerLocation(x, y);
  }

  updatePlayerLocation(newX, newY) {
    this.player.setLocation(newX, newY);
  }

  checkIfPlayerWillCollectItem(x, y) {
    const item = this.scene.getCollectible(x, y);
    if (item) {
      this.updatePlayerScore(this.player.getScore() + item.props.points);
      this.scene.removeCollectible(x, y);
      playItemCollectedSound();
    }
  }

  checkIfPlayerWillEncounterAnEnemy(x, y) {
    const enemy = this.scene.getEnemy(x, y);
    if (enemy) {
      switch (enemy.props.action.type) {
        case 'POPUP':
          bigSplashSound.play();
          this.game.askQuestion(enemy.props.action);
          this.scene.removeEnemy(x, y);
          break;
        default:
          break;
      }
    }
  }

  checkIfPlayerWillEncounterPortal(x, y) {
    const portal = this.scene.getPortal(x, y);
    if (portal) {
      switch (portal.action.type) {
        case 'ADVANCE':
          playGame.play();
          this.game.showActionDialog(portal.action);
          break;
        case 'END_GAME':
          playGame.play();
          this.game.showActionDialog(portal.action.getDialog(this.player.getScore()));
          break;
        default:
          break;
      }
    }
  }

  updatePlayerScore(newScore) {
    this.player.setScore(newScore);
  }

  clearPreviousLocationTile(x, y) {
    this.scene.setTileContent(x, y, undefined);
  }
}

export default Controller;
