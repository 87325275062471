import React from 'react';
import Scene from '../../Entities/Board/Scene';
import Droplet from '../../Entities/Item/Droplet';
import Grease from '../../Entities/Enemies/Grease';
import WetWipe from '../../Entities/Enemies/WetWipe';
import { Questions, Dialogs } from '../ModalText/Level1';
import Portal from '../../Entities/Elements/Portal';
import { PipeImages } from '../../Entities/Board/Path/Path';
import Images from '../Images/Item';

class Level1 {
  constructor() {
    this.reset();
  }

  reset() {
    this.scene = undefined;
    this.scene = new Scene(30, 20);
    this.scene.setBackground(Images.level1);
    // this.scene.showTilePosition(true);

    // Path
    this.scene.addPathTile(3, 9, 'transparent', PipeImages.end90);
    this.scene.addPathTile(3, 10, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(3, 11, 'transparent', PipeImages.T270);
    this.scene.addPathTile(3, 12, 'transparent', PipeImages.end270);
    this.scene.addPathTile(4, 11, 'transparent', PipeImages.straight);
    this.scene.addPathTile(5, 11, 'transparent', PipeImages.straight);
    this.scene.addPathTile(6, 11, 'transparent', PipeImages.corner);
    this.scene.addPathTile(6, 12, 'transparent', PipeImages.T270);
    this.scene.addPathTile(6, 13, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(6, 14, 'transparent', PipeImages.corner90);
    this.scene.addPathTile(5, 14, 'transparent', PipeImages.straight);
    this.scene.addPathTile(4, 14, 'transparent', PipeImages.straight);
    this.scene.addPathTile(3, 14, 'transparent', PipeImages.corner270);
    this.scene.addPathTile(3, 15, 'transparent', PipeImages.corner90);
    this.scene.addPathTile(2, 15, 'transparent', PipeImages.straight);
    this.scene.addPathTile(1, 15, 'transparent', PipeImages.end);
    this.scene.addPathTile(7, 12, 'transparent', PipeImages.straight);
    this.scene.addPathTile(8, 12, 'transparent', PipeImages.straight);
    this.scene.addPathTile(9, 12, 'transparent', PipeImages.corner);
    this.scene.addPathTile(9, 13, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(9, 14, 'transparent', PipeImages.T270);
    this.scene.addPathTile(9, 15, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(9, 16, 'transparent', PipeImages.T180);
    this.scene.addPathTile(8, 16, 'transparent', PipeImages.straight);
    this.scene.addPathTile(7, 16, 'transparent', PipeImages.corner270);
    this.scene.addPathTile(7, 17, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(7, 18, 'transparent', PipeImages.corner90);
    this.scene.addPathTile(6, 18, 'transparent', PipeImages.end);
    this.scene.addPathTile(27, 11, 'transparent', PipeImages.straight);
    this.scene.addPathTile(16, 11, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(12, 10, 'transparent', PipeImages.straight);
    this.scene.addPathTile(15, 17, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(19, 15, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(19, 16, 'transparent', PipeImages.end270);
    this.scene.addPathTile(11, 14, 'transparent', PipeImages.T);
    this.scene.addPathTile(12, 14, 'transparent', PipeImages.straight);
    this.scene.addPathTile(13, 14, 'transparent', PipeImages.straight);
    this.scene.addPathTile(10, 16, 'transparent', PipeImages.corner);
    this.scene.addPathTile(10, 17, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(10, 18, 'transparent', PipeImages.corner180);
    this.scene.addPathTile(11, 18, 'transparent', PipeImages.straight);
    this.scene.addPathTile(12, 17, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(12, 18, 'transparent', PipeImages.corner90);
    this.scene.addPathTile(24, 15, 'transparent', PipeImages.straight);
    this.scene.addPathTile(25, 15, 'transparent', PipeImages.straight);
    this.scene.addPathTile(26, 15, 'transparent', PipeImages.corner);
    this.scene.addPathTile(26, 16, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(26, 18, 'transparent', PipeImages.end270);
    this.scene.addPathTile(19, 14, 'transparent', PipeImages.corner);
    this.scene.addPathTile(12, 16, 'transparent', PipeImages.corner270);
    this.scene.addPathTile(13, 16, 'transparent', PipeImages.straight);
    this.scene.addPathTile(14, 16, 'transparent', PipeImages.straight);
    this.scene.addPathTile(15, 16, 'transparent', PipeImages.corner);
    this.scene.addPathTile(11, 10, 'transparent', PipeImages.corner270);
    this.scene.addPathTile(13, 10, 'transparent', PipeImages.T90);
    this.scene.addPathTile(13, 9, 'transparent', PipeImages.end90);
    this.scene.addPathTile(16, 10, 'transparent', PipeImages.corner270);
    this.scene.addPathTile(16, 12, 'transparent', PipeImages.corner180);
    this.scene.addPathTile(15, 18, 'transparent', PipeImages.corner180);
    this.scene.addPathTile(21, 12, 'transparent', PipeImages.corner);
    this.scene.addPathTile(21, 18, 'transparent', PipeImages.corner90);
    this.scene.addPathTile(23, 10, 'transparent', PipeImages.corner);
    this.scene.addPathTile(23, 15, 'transparent', PipeImages.corner180);
    this.scene.addPathTile(24, 17, 'transparent', PipeImages.end);
    this.scene.addPathTile(28, 17, 'transparent', PipeImages.corner90);
    this.scene.addPathTile(28, 11, 'transparent', PipeImages.corner);
    this.scene.addPathTile(26, 9, 'transparent', PipeImages.end90);
    this.scene.addPathTile(26, 10, 'transparent', PipeImages.straight90);
    this.scene.addPathTile(26, 11, 'transparent', PipeImages.corner180);

    for (let i = 11; i < 14; i += 1) {
      this.scene.addPathTile(11, i, 'transparent', PipeImages.straight90);
    }
    for (let i = 11; i < 14; i += 1) {
      this.scene.addPathTile(13, i, 'transparent', PipeImages.straight90);
    }
    for (let i = 10; i < 19; i += 1) {
      this.scene.addPathTile(i, 14, 'transparent', [11, 13].includes(i) ? PipeImages.T180 : PipeImages.straight);
    }
    for (let i = 17; i < 23; i += 1) {
      this.scene.addPathTile(i, 10, 'transparent', PipeImages.straight);
    }
    for (let i = 17; i < 21; i += 1) {
      this.scene.addPathTile(i, 12, 'transparent', PipeImages.straight);
    }
    for (let i = 16; i < 21; i += 1) {
      this.scene.addPathTile(i, 18, 'transparent', PipeImages.straight);
    }
    for (let i = 13; i < 18; i += 1) {
      this.scene.addPathTile(21, i, 'transparent', PipeImages.straight90);
    }
    for (let i = 11; i < 15; i += 1) {
      this.scene.addPathTile(23, i, 'transparent', PipeImages.straight90);
    }
    for (let i = 25; i < 28; i += 1) {
      this.scene.addPathTile(i, 17, 'transparent', i === 26 ? PipeImages.cross : PipeImages.straight);
    }
    for (let i = 12; i < 17; i += 1) {
      this.scene.addPathTile(28, i, 'transparent', PipeImages.straight90);
    }

    this.scene.setStartTile(3, 9);
    this.scene.addCollectible(<Droplet {...{ x: 8, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 9, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 8, y: 16, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 7, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 10, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 11, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 12, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 12, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 18, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 15, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 17, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 19, y: 15, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 21, y: 16, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 21, y: 13, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 20, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 11, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 15, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 17, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 20, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 16, y: 11, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 19, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 21, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 23, y: 11, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 24, y: 15, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 25, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 27, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 28, y: 15, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 28, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 26, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 4, y: 11, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 4, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 2, y: 15, points: 5 }} />);

    this.scene.addEnemy(<Grease {...{ x: 14, y: 16, action: { type: 'POPUP', ...Questions.ENEMY1 } }} />);
    this.scene.addEnemy(<WetWipe {...{ x: 26, y: 15, action: { type: 'POPUP', ...Questions.ENEMY2 } }} />);

    this.scene.addPortal(new Portal(26, 9, { type: 'ADVANCE', ...Dialogs.PORTAL }));
  }
}

export {
  Dialogs,
  Questions,
};

export default Level1;
