/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  TransitionablePortal,
  Button,
  Form,
  Radio,
  Transition,
} from 'semantic-ui-react';
import Images from '../../Assets/Images/Item';
import {
  wrongAnswer,
  correctAnswer,
  playMoveSound,
  howToPlay,
} from '../../Assets/Sounds';

const isIE = navigator.userAgent.indexOf('MSIE') !== -1
|| navigator.appVersion.indexOf('Trident/') > -1;
class QuestionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatement: true,
      showQuestion: false,
      showResult: false,
      answerSelection: undefined,
      delayButton: true,
      open: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.buttonClicked = this.buttonClicked.bind(this);
    this.delayButton = this.delayButton.bind(this);
    this.delayButton();
  }

  handleChange(e, { value }) {
    playMoveSound();
    this.setState({ answerSelection: value });
  }

  delayButton() {
    setTimeout(() => {
      this.setState({
        delayButton: false,
      });
      if (this.actionButton) {
        this.actionButton.focus();
      }
    }, 5000);
  }

  buttonClicked() {
    const {
      showStatement,
      showQuestion,
      showResult,
      answerSelection,
    } = this.state;
    const { question } = this.props;
    if (showStatement) {
      howToPlay.play();
      this.setState({
        showStatement: false,
        showQuestion: true,
        delayButton: false,
      });
      if (this.answerOptions) {
        this.answerOptions.focus();
      }
    } else if (showQuestion) {
      const result = answerSelection ===
      question.ANSWER_OPTIONS[question.CORRECT_ANSWER_INDEX];
      if (result) {
        correctAnswer.play();
      } else {
        wrongAnswer.play();
      }
      this.setState({
        showQuestion: false,
        showResult: true,
        delayButton: true,
      });
      this.delayButton();
    } else if (showResult) {
      const { questionComplete } = this.props;
      const result = answerSelection ===
      question.ANSWER_OPTIONS[question.CORRECT_ANSWER_INDEX];
      if (!result) {
        howToPlay.play();
      }
      this.setState({
        open: false,
      }, () => {
        setTimeout(() => {
          questionComplete(result, question.START_GAME);
          if (result) {
            correctAnswer.play();
          }
        }, 505);
      });
    }
  }

  render() {
    const { question } = this.props;
    const {
      showQuestion,
      showStatement,
      showResult,
      answerSelection,
      delayButton,
      open,
    } = this.state;
    return (
      <TransitionablePortal
        open={open}
        transition={{ animation: 'zoom', duration: 500 }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeOnTriggerClick={false}
        closeOnDocumentClick={false}
        closeOnTriggerMouseLeave={false}
      >
        <Modal
          open
          onClick={() => { this.actionButton.focus(); }}
          centered
          style={{
            top: '3%',
            left: 0,
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            maxHeight: '100vh',
            }}
          basic
        >
          <Modal.Header
            style={{
              fontSize: 30,
              border: 'none',
              display: 'block',
              padding: 0,
              transform: window.innerWidth < 500 || window.innerHeight < 600
              ? 'scale(0.5)'
              : window.innerWidth < 1000 || window.innerHeight < 900
                ? 'scale(0.8)'
                : undefined,
            }}
          >
            {question && question.NAME}
          </Modal.Header>
          <Modal.Content style={{
              padding: 0,
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              transform: window.innerWidth < 500 || window.innerHeight < 600
              ? 'scale(0.5)'
              : window.innerWidth < 1000 || window.innerHeight < 900
                ? 'scale(0.8)'
                : undefined,
            }}
          >
            <div
              style={{
                padding: 0,
                width: '100vw',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${Images.speechBubble})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  textAlign: 'center',
                  height: 600,
                  width: 600,
                  padding: '',
                  margin: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                }}
              >
                <p style={{
                    color: 'black',
                    fontSize: 19,
                    width: '70%',
                    height: '45%',
                    textAlign: 'center',
                    display: 'flex',
                    ...(!isIE ? {
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '10%',
                    } :
                    {}),
                  }}
                >
                  {question &&
                    <>
                      {showStatement && question.STATEMENT}
                      {showQuestion && question.QUESTION}
                      {!showQuestion && !showStatement &&
                        (showResult &&
                          answerSelection === question.ANSWER_OPTIONS[question.CORRECT_ANSWER_INDEX]
                          ? question.CORRECT_REMARK
                          : question.INCORRECT_REMARK)}
                    </>}
                </p>
              </div>
              {showQuestion &&
                <Form
                  ref={(r) => { this.answerOptions = r; }}
                  style={{
                    width: 500,
                    position: 'relative',
                    left: 45,
                    marginBottom: 100,
                  }}
                >
                  <Form.Field style={{ fontSize: 20 }}>
                    Select Your Answer:
                  </Form.Field>
                  {question.ANSWER_OPTIONS.map((o, i) => (
                    <Form.Field
                      // eslint-disable-next-line
                      key={`emwd-gao-${i}`}
                      className="emwd-gao"
                      onClick={() => this.handleChange(undefined, { value: o })}
                    >
                      <Radio
                        label={o}
                        name="answerGroup"
                        value={o}
                        checked={answerSelection === o}
                        onChange={this.handleChange}
                        onClick={() => { this.actionButton.focus(); }}
                        fitted
                      />
                    </Form.Field>
                  ))}
                </Form>}
            </div>
            {question &&
              <Transition
                visible={showStatement || showResult}
                animation={question.START_GAME ? 'bounce' : 'tada'}
                duration={1000}
                transitionOnMount
              >
                <img
                  src={showStatement || showQuestion
                    ? question.ENEMY_IMAGE
                    : showResult &&
                      answerSelection === question.ANSWER_OPTIONS[question.CORRECT_ANSWER_INDEX]
                      ? question.CORRECT_ANSWER_IMAGE
                      : question.WRONG_ANSWER_IMAGE}
                  alt={question.NAME}
                  width={256}
                  height={256}
                  style={{
                    height: 375,
                    width: 'auto',
                    position: 'relative',
                    left: showQuestion ? -80 : 160,
                    bottom: 220,
                  }}
                />
              </Transition>}
            <Button
              disabled={(showQuestion && !answerSelection) || delayButton}
              onClick={this.buttonClicked}
              ref={(r) => { this.actionButton = r; }}
              onKeyDown={(e) => {
                e.preventDefault();
                if (e.keyCode === 13 || e.keyCode === 39) {
                  this.buttonClicked();
                }
              }}
              size="massive"
              primary
              style={{
                width: 217,
                height: 75,
                backgroundImage: `url(${Images.continueArrow})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'transparent',
                position: 'relative',
                left: 450,
                bottom: 150,
                zIndex: 1200,
              }}
            />
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

QuestionModal.defaultProps = {
  question: undefined,
  questionComplete: () => {},
  // isFullscreen: false,
};

QuestionModal.propTypes = {
  question: PropTypes.shape({}),
  questionComplete: PropTypes.func,
  // isFullscreen: PropTypes.bool,
};

export default QuestionModal;
