import React from 'react';
import { Transition } from 'semantic-ui-react';
import gobiRightImage from '../../Assets/Images/Characters/gobi.png';
import gobiLeftImage from '../../Assets/Images/Characters/gobi1.png';

const DIRECTION = {
  1: gobiRightImage,
  '-1': gobiLeftImage,
};

class Player {
  constructor(x, y) {
    this.score = 0;
    this.scoreChanged = true;
    this.imageSize = { width: 380, height: 214 };
    this.direction = -1;
    this.location = {
      x,
      y,
    };
  }

  getScore() {
    return this.score;
  }

  setScore(value) {
    this.scoreChanged = !this.scoreChanged;
    this.score = value;
  }

  setLocation(x, y) {
    this.location = {
      x,
      y,
    };
  }

  setDirection(direction) {
    switch (direction) {
      case 'LEFT':
        this.direction = 1;
        break;
      case 'RIGHT':
        this.direction = -1;
        break;
      default:
        break;
    }
  }

  setImage(imagePath, size = { width: 70, height: 70 }) {
    this.image = imagePath;
    this.imageSize = size;
  }

  getRenderObject() {
    return (
      <Transition visible animation="jiggle" transitionOnMount mountOnShow duration={500}>
        <img
          className="emwd-gplayer"
          src={DIRECTION[this.direction]}
          style={{
            height: this.imageSize.height,
            width: this.imageSize.width,
            top: -(this.imageSize.height / 2),
            left: -(this.imageSize.width / 2) + 15,
            position: 'absolute',
            margin: 0,
            padding: 0,
            zIndex: 25,
          }}
          alt="Game character."
        />
      </Transition>
    );
  }
}

export default Player;
