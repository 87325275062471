/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Tile from '../Tile';
import './Board.css';

const Board = ({
  backgroundColor,
  backgroundImage,
  border,
  dimX,
  dimY,
  tileHeight,
  tileWidth,
  path,
  tileStyles,
  showTilePosition,
  fullscreen,
  collectibles,
  enemies,
  overlay,
}) => {
  const tiles = [];
  const h = ((1.0 / tileHeight) / (dimY * tileHeight)) * 100;
  const w = ((1.0 / tileWidth) / (dimX * tileWidth)) * 100;
  for (let height = 0; height < dimY; height += 1) {
    tiles.push([]);
    for (let width = 0; width < dimX; width += 1) {
      const pathProps = path && path.getTile(width, height);
      const tileStyle = tileStyles.get(`${width}-${height}`);
      const tileContent = (pathProps && pathProps.content) ||
        enemies.get(`${width}-${height}`) || collectibles.get(`${width}-${height}`);
      if (pathProps && pathProps.content) {
        pathProps.content = undefined;
      }
      const t = (
        <Tile
          {...{
            height: `${h}%`,
            width: `${w}%`,
            leftEdge: width === 0,
            rightEdge: width === (dimX - 1),
            topEdge: height === 0,
            bottomEdge: height === (dimY - 1),
            xPosition: width,
            yPosition: height,
            ...tileStyle,
            ...pathProps,
            key: `gbt-${width}-${height}`,
            showPosition: showTilePosition,
          }}
        >
          {tileContent}
        </Tile>);
      tiles[height]
        .push(t);
    }
  }
  const height = dimY * tileHeight;
  const width = dimX * tileWidth;
  return (
    <div
      style={{
        backgroundColor,
        backgroundImage: backgroundImage && `url(${backgroundImage})`,
        border,
        width,
        height,
        lineHeight: showTilePosition ? undefined : 0,
        position: 'absolute',
        left: (window.innerWidth / 2) - ((dimX * tileWidth) / 2),
        top: ((window.innerHeight / 2) - ((dimY * tileHeight) / 2)),
        marginRight: fullscreen ? 0 : undefined,
        marginLeft: fullscreen ? 0 : undefined,
        transform: fullscreen
          ? 'scale(1.50)'
          : window.innerWidth < 500 || window.innerHeight < 600
            ? 'scale(0.5)'
            : window.innerWidth < 1000 || window.innerHeight < 900
              ? 'scale(0.8)'
              : undefined,
      }}
      className="emwd-gbb"
    >
      {tiles}
      {overlay}
    </div>
  );
};

Board.defaultProps = {
  tileHeight: undefined,
  tileWidth: undefined,
  dimX: 10,
  dimY: 10,
  backgroundColor: undefined,
  backgroundImage: undefined,
  border: undefined,
  path: undefined,
  showTilePosition: undefined,
  tileStyles: undefined,
  fullscreen: undefined,
  collectibles: undefined,
  enemies: undefined,
  overlay: undefined,
};

Board.propTypes = {
  tileHeight: PropTypes.number,
  tileWidth: PropTypes.number,
  dimX: PropTypes.number,
  dimY: PropTypes.number,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  border: PropTypes.string,
  path: PropTypes.shape({}),
  showTilePosition: PropTypes.bool,
  tileStyles: PropTypes.shape({}),
  fullscreen: PropTypes.bool,
  collectibles: PropTypes.shape({}),
  enemies: PropTypes.shape({}),
  overlay: PropTypes.shape({}),
};

export default Board;
