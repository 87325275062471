import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Enemy from './Enemy';
import { getRandomArbitrary } from '../../../Helpers/math.helpers';
import wetWipeImage from '../../Assets/Images/Characters/wetwipe.png';

class WetWipe extends Component {
  constructor(props) {
    super(props);
    const {
      x,
      y,
      action,
    } = props;
    const enemy = new Enemy(x, y, wetWipeImage, action);
    enemy.width = 290;
    enemy.height = 'auto';
    enemy.top = -85;
    enemy.left = -140;
    this.state = {
      enemy,
      animationInterval: undefined,
    };
    this.initAnimationTimer = this.initAnimationTimer.bind(this);
  }

  componentDidMount() {
    this.initAnimationTimer();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.animate !== nextState.animate;
  }

  componentWillUnmount() {
    const { animationInterval } = this.state;
    clearInterval(animationInterval);
    this.setState({
      animationInterval,
    });
  }

  initAnimationTimer() {
    const intervalTime = getRandomArbitrary(5000, 10000);
    const animationInterval = setInterval(() => {
      this.setState({
        animate: !this.state.animate,
      });
    }, intervalTime);
    this.setState({
      animationInterval,
    });
  }

  render() {
    const { enemy, animate } = this.state;
    return (
      <Fragment>
        {enemy.getRenderObject(animate, 'tada')}
      </Fragment>
    );
  }
}

WetWipe.defaultProps = {
  x: undefined,
  y: undefined,
  action: undefined,
};

WetWipe.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  action: PropTypes.shape({}),
};

export default WetWipe;
