import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Enemy from './Enemy';
import { getRandomArbitrary } from '../../../Helpers/math.helpers';
import savvyImage from '../../Assets/Images/Characters/savvy.png';

class Savvy extends Component {
  constructor(props) {
    super(props);
    const {
      x,
      y,
      action,
    } = props;
    const enemy = new Enemy(x, y, savvyImage, action);
    enemy.width = 355;
    enemy.height = 'auto';
    enemy.top = -106;
    enemy.left = -160;
    this.state = {
      enemy,
      animationInterval: undefined,
    };
    this.initAnimationTimer = this.initAnimationTimer.bind(this);
  }

  componentDidMount() {
    this.initAnimationTimer();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.animate !== nextState.animate;
  }

  componentWillUnmount() {
    const { animationInterval } = this.state;
    clearInterval(animationInterval);
    this.setState({
      animationInterval,
    });
  }

  initAnimationTimer() {
    const intervalTime = getRandomArbitrary(6000, 11000);
    const animationInterval = setInterval(() => {
      this.setState({
        animate: !this.state.animate,
      });
    }, intervalTime);
    this.setState({
      animationInterval,
    });
  }

  render() {
    const { enemy, animate } = this.state;
    return (
      <Fragment>
        {enemy.getRenderObject(animate, 'tada')}
      </Fragment>
    );
  }
}

Savvy.defaultProps = {
  x: undefined,
  y: undefined,
  action: undefined,
};

Savvy.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  action: PropTypes.shape({}),
};

export default Savvy;
