import React from 'react';
import { Transition } from 'semantic-ui-react';
import Item from './Item';

class Collectible extends Item {
  constructor(x, y, image, points, action) {
    super(x, y, image, action);
    this.width = 15;
    this.height = 'auto';
    this.points = points;
  }

  getRenderObject(animate) {
    return (
      <Transition visible={animate} animation="bounce" duration={1000} transitionOnMount>
        <img
          className="emwd-gcollectible"
          src={this.imagePath}
          width={this.width}
          height={this.height}
          style={{
            height: this.height,
            width: this.width,
          }}
          alt="Game collectible item."
        />
      </Transition>
    );
  }
}

export default Collectible;
