import React from 'react';
import Game from './Game';
import bg from './Game/Assets/Images/drops.png';

const App = () => (
  <div className="App" style={{ backgroundImage: `url(${bg})`, backgroundPositionY: -75, backgroundRepeat: 'no-repeat' }}>
    <Game />
  </div>
);

export default App;
