import React from 'react';
import { TransitionablePortal } from 'semantic-ui-react';
import ReactTouchEvents from 'react-touch-events';
import Path from '../Path';
import Board from '../Board';

class Scene {
  constructor(width, height) {
    this.path = new Path();
    this.tiles = new Map();
    this.collectibles = new Map();
    this.enemies = new Map();
    this.portals = new Map();
    this.sceneWidth = width;
    this.sceneHeight = height;
    this.showTileMarkers = undefined;
    this.fullscreen = undefined;
  }

  setBackgroundColor(color) {
    this.backgroundColor = color;
  }

  setBackground(imagePath) {
    this.boardBackgroundImage = imagePath;
  }

  setStartTile(x, y) {
    this.path.setStartTile(x, y);
  }

  getStartTile() {
    return this.path.getStartTile() || { xPosition: 0, yPosition: 0 };
  }

  getPathTile(x, y) {
    return this.path.getTile(x, y);
  }

  setTileContent(x, y, content) {
    this.path.setTileContent(x, y, content);
  }

  getBoard(overlay, hideMainStage, touchEventHandler) {
    return (
      <TransitionablePortal
        open={!hideMainStage}
        transition={{ animation: 'fade', duration: '700' }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeOnTriggerClick={false}
        closeOnDocumentClick={false}
        closeOnTriggerMouseLeave={false}
      >
        <div>
          <ReactTouchEvents onSwipe={touchEventHandler} swipeTolerance={125}>
            <div>
              <Board
                dimX={this.sceneWidth}
                dimY={this.sceneHeight}
                tileHeight={32}
                tileWidth={32}
                backgroundColor={this.backgroundColor}
                backgroundImage={this.boardBackgroundImage}
                path={this.path}
                tileStyles={this.tiles}
                showTilePosition={this.showTileMarkers}
                fullscreen={this.fullscreen}
                collectibles={this.collectibles}
                enemies={this.enemies}
                overlay={overlay}
                hideMainStage={hideMainStage}
              />
            </div>
          </ReactTouchEvents>
        </div>
      </TransitionablePortal>
    );
  }

  addPathTile(x, y, color, image, colorOverlay) {
    this.path.addTile(x, y, image, color, colorOverlay);
  }

  addTile(x, y, color, image) {
    this.tiles.set(`${x}-${y}`, {
      xPosition: x,
      yPosition: y,
      backgroundColor: color,
      backgroundImage: image,
    });
  }

  exitFullscreen() {
    this.fullscreen = undefined;
  }

  goFullscreen() {
    this.fullscreen = true;
  }

  showTilePosition(show) {
    this.showTileMarkers = show;
  }

  addCollectible(c) {
    this.collectibles.set(`${c.props.x}-${c.props.y}`, c);
  }

  getCollectible(x, y) {
    return this.collectibles.get(`${x}-${y}`);
  }

  removeCollectible(x, y) {
    this.collectibles.set(`${x}-${y}`, undefined);
  }

  addEnemy(e) {
    this.enemies.set(`${e.props.x}-${e.props.y}`, e);
  }

  removeEnemy(x, y) {
    this.enemies.set(`${x}-${y}`, undefined);
  }

  getEnemy(x, y) {
    return this.enemies.get(`${x}-${y}`);
  }

  addPortal(p) {
    this.portals.set(`${p.x}-${p.y}`, p);
  }

  getPortal(x, y) {
    return this.portals.get(`${x}-${y}`);
  }
}

export default Scene;
