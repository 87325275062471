import itemCollectedSound from './collectItem.mp3';
import bgMusic from './bgmusic.mp3';
import toiletFlushSound from './toilet.mp3';
import gobiMoveSound from './gobiMove.mp3';
import bigSplash from './bigSplash.mp3';
import wrongAnswerSound from './wrongAnswer.mp3';
import correctAnswerSound from './correctAnswer.mp3';
import meetCharactersSound from './meetCharacters.mp3';
import backToMenuSound from './backToMainMenu.mp3';
import howToPlaySound from './howToPlay.mp3';
import playGameSound from './playGame.mp3';

const backgroundMusic = new Audio(bgMusic);
backgroundMusic.preload = true;
backgroundMusic.loop = true;
backgroundMusic.volume = 0.52;

const toiletFlush = new Audio(toiletFlushSound);
toiletFlush.preload = true;
toiletFlush.volume = 0.20;

const bigSplashSound = new Audio(bigSplash);
bigSplashSound.preload = true;
bigSplashSound.volume = 0.65;

const wrongAnswer = new Audio(wrongAnswerSound);
wrongAnswer.preload = true;
wrongAnswer.volume = 0.70;

const correctAnswer = new Audio(correctAnswerSound);
correctAnswer.preload = true;
correctAnswer.volume = 0.70;

const meetCharacters = new Audio(meetCharactersSound);
meetCharacters.preload = true;
meetCharacters.volume = 0.70;

const backToMenu = new Audio(backToMenuSound);
backToMenu.preload = true;
backToMenu.volume = 0.70;

const howToPlay = new Audio(howToPlaySound);
howToPlay.preload = true;
howToPlay.volume = 0.70;

const playGame = new Audio(playGameSound);
playGame.preload = true;
playGame.volume = 0.70;

let canPlayMoveSound = true;
let canPlayMoveSound2 = true;
let canPlayMoveSound3 = true;

const moveSound = new Audio(gobiMoveSound);
moveSound.preload = true;
moveSound.volume = 0.45;
moveSound.addEventListener('playing', () => {
  canPlayMoveSound = false;
});
moveSound.addEventListener('ended', () => {
  canPlayMoveSound = true;
});

const moveSound2 = new Audio(gobiMoveSound);
moveSound2.preload = true;
moveSound2.volume = 0.45;
moveSound2.addEventListener('playing', () => {
  canPlayMoveSound2 = false;
});
moveSound2.addEventListener('ended', () => {
  canPlayMoveSound2 = true;
});

const moveSound3 = new Audio(gobiMoveSound);
moveSound3.preload = true;
moveSound3.volume = 0.45;
moveSound3.addEventListener('playing', () => {
  canPlayMoveSound3 = false;
});
moveSound3.addEventListener('ended', () => {
  canPlayMoveSound3 = true;
});

const moveSound4 = new Audio(gobiMoveSound);
moveSound4.preload = true;
moveSound4.volume = 0.45;

const playMoveSound = () => {
  if (canPlayMoveSound) {
    moveSound.play();
  } else if (canPlayMoveSound2) {
    moveSound2.play();
  } else if (canPlayMoveSound3) {
    moveSound3.play();
  } else {
    moveSound4.play();
  }
};

let canPlayItemCollected = true;
let canPlayItemCollected2 = true;

const itemCollected = new Audio(itemCollectedSound);
itemCollected.volume = 0.35;
itemCollected.preload = true;
itemCollected.addEventListener('playing', () => {
  canPlayItemCollected = false;
});
itemCollected.addEventListener('ended', () => {
  canPlayItemCollected = true;
});

const itemCollected2 = new Audio(itemCollectedSound);
itemCollected2.volume = 0.35;
itemCollected2.preload = true;
itemCollected2.addEventListener('playing', () => {
  canPlayItemCollected2 = false;
});
itemCollected.addEventListener('ended', () => {
  canPlayItemCollected2 = true;
});

const itemCollected3 = new Audio(itemCollectedSound);
itemCollected3.volume = 0.35;
itemCollected3.preload = true;

const playItemCollectedSound = () => {
  if (canPlayItemCollected) {
    itemCollected.play();
  } else if (canPlayItemCollected2) {
    itemCollected2.play();
  } else {
    itemCollected3.play();
  }
};

export {
  playItemCollectedSound,
  toiletFlushSound,
  backgroundMusic,
  playMoveSound,
  toiletFlush,
  bigSplashSound,
  correctAnswer,
  wrongAnswer,
  meetCharacters,
  backToMenu,
  howToPlay,
  playGame,
};

export default {
  itemCollectedSound,
  bgMusic,
  toiletFlushSound,
  gobiMoveSound,
  bigSplash,
  wrongAnswerSound,
  correctAnswerSound,
  meetCharactersSound,
  backToMenuSound,
  howToPlaySound,
  playGameSound,
};
