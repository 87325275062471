import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Transition } from 'semantic-ui-react';
import emwdLogoImage from './Assets/Images/Item/emwdext.png';
import Images from './Assets/Images/Item';

const MainMenu = ({ onClickPlay, onClickMeetGobi, onClickHowToPlay }) => (
  <Transition animation="fade up" duratio={500} transitionOnMount>
    <div
      id="emwd-gobi-main-menu"
      style={{
        zIndex: 24,
        width: 960,
        height: 640,
        backgroundColor: 'rgb(0, 0, 0, 0)',
        position: 'relative',
        display: 'inline-block',
        top: -640,
        left: 0,
        paddingTop: 15,
        boxSizing: 'border-box',
      }}
    >
      <img
        src={Images.gameTitle}
        alt="Gobi's Amazing Adveture"
        style={{
          position: 'relative',
          display: 'inline-block',
          top: 290,
          color: 'white',
          width: 365,
          fontSize: 50,
          textAlign: 'center',
          whiteSpace: 'pre',
          marginLeft: 20,
        }}
      />
      <div style={{ top: -290, position: 'relative' }}>
        <Button
          color="black"
          size="massive"
          onClick={onClickMeetGobi}
          inverted
          style={{
            position: 'relative',
            border: 0,
            top: 350,
            left: 420,
            zIndex: 25,
            width: 217,
            height: 75,
            fontSize: 29,
            margin: 0,
            backgroundColor: 'transparent',
            backgroundImage: `url(${Images.meetCharactersButton})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        />
        <Button
          color="black"
          size="massive"
          onClick={onClickHowToPlay}
          inverted
          style={{
            position: 'relative',
            top: 349,
            left: 450,
            zIndex: 25,
            width: 217,
            height: 75,
            fontSize: 29,
            margin: 0,
            backgroundColor: 'transparent',
            backgroundImage: `url(${Images.howToPlayButton})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        />
        <Button
          color="black"
          size="massive"
          onClick={onClickPlay}
          inverted
          style={{
            position: 'relative',
            top: 450,
            left: -14,
            zIndex: 25,
            width: 217,
            height: 75,
            fontSize: 29,
            margin: 0,
            backgroundColor: 'transparent',
            backgroundImage: `url(${Images.playButton})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        />
        <Image
          src={emwdLogoImage}
          alt="EMWD Logo"
          style={{
            position: 'relative',
            width: 300,
            top: 340,
            left: 640,
            padding: 25,
          }}
        />
      </div>
      <img
        src={Images.waves}
        alt="Waves animates"
        style={{
          position: 'relative',
          bottom: -40,
        }}
      />
    </div>
  </Transition>
);

MainMenu.defaultProps = {
  onClickPlay: () => {},
  onClickMeetGobi: () => {},
  onClickHowToPlay: () => {},
};

MainMenu.propTypes = {
  onClickPlay: PropTypes.func,
  onClickMeetGobi: PropTypes.func,
  onClickHowToPlay: PropTypes.func,
};

export default MainMenu;
