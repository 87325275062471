import meetCharactersButton from './meetcharactersbutton.png';
import playButton from './playbutton.png';
import howToPlayButton from './howtoplaybutton.png';
import gameTitle from './gametitle.png';
import waves from './waves.png';
import mainMenuButton from './mainmenubutton.png';
import speechBubble from './Game-Art-v2-peices_balloon.png';
import continueArrow from './Game-Art-v2-peices_continue-arrow.png';
import caMap from './camap.png';
import level2 from './level1.png';
import level1 from './level2.png';
import closeButton from './Game-Art-v2-peices_close-button.png';
import mainMenuPurple from './mainmenupurple.png';
import emwdWhiteLogo from './emwdext.png';
import theSun from './thesun.png';

export default {
  meetCharactersButton,
  howToPlayButton,
  mainMenuButton,
  gameTitle,
  playButton,
  waves,
  speechBubble,
  continueArrow,
  caMap,
  level1,
  level2,
  closeButton,
  mainMenuPurple,
  emwdWhiteLogo,
  theSun,
};
