class Item {
  constructor(x, y, imagePath, action) {
    this.xPosition = x;
    this.yPosition = y;
    this.imagePath = imagePath;
    this.action = action;
  }
}

export default Item;
