import React from 'react';
import { Image, Transition } from 'semantic-ui-react';
import keysImage from './Assets/Images/Item/arrowKeys.png';

const HowToPlay = () => (
  <Transition animation="fade left" duratio={500} transitionOnMount>
    <div
      id="emwd-gobi-how-to-play"
      style={{
        zIndex: 24,
        width: 960,
        height: 640,
        backgroundColor: 'rgb(0, 0, 0, 0)',
        position: 'relative',
        display: 'inline-block',
        top: -650,
        left: 50,
        paddingTop: 20,
      }}
    >
      <h1
        style={{
          display: 'block',
          color: 'white',
          position: 'relative',
          fontSize: 40,
          textAlign: 'left',
          whiteSpace: 'pre',
          top: 290,
          left: 30,
        }}
      >
        How to Play
      </h1>
      <h2
        style={{
          display: 'block',
          color: 'white',
          position: 'relative',
          fontSize: 30,
          textAlign: 'left',
          whiteSpace: 'pre',
          top: 280,
          left: 30,
        }}
      >
        Controls
      </h2>
      <Image
        src={keysImage}
        alt="Gobi. (Main character)"
        style={{
          display: 'block',
          position: 'relative',
          width: 170,
          top: 275,
          left: 40,
        }}
      />
      <h2
        style={{
          display: 'block',
          color: 'white',
          position: 'relative',
          fontSize: 30,
          textAlign: 'left',
          whiteSpace: 'pre',
          top: 83,
          left: 240,
        }}
      >
        Game Objective
      </h2>
      <p
        style={{
          position: 'relative',
          color: 'white',
          fontSize: 15,
          whiteSpace: 'normal',
          top: 80,
          left: 240,
          maxWidth: 630,
        }}
      >
        {`Use the keyboard arrows to navigate Gobi through
          the pipe maze to reach the end of the treatment
          process! As you move through the maze, Gobi will
          run into water contaminants and other characters.
          Correctly answer each question to collect points and
          keep going! Collect water droplets along the way to
          win more points! Gain 20 points for each question
          answered correctly and 5 points for every water
          drop collected.`}
      </p>
    </div>
  </Transition>
);

export default HowToPlay;
