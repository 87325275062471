import React from 'react';
import { Button, Confirm, Transition } from 'semantic-ui-react';
import Controller from './Controller';
import Player from './Entities/Player';
import QuestionModal from './Entities/Elements/QuestionModal';
import ActionDialog from './Entities/Elements/ActionDialog';
import MainMenu from './MainMenu';
import MeetGobi from './MeetGobi';
import HowToPlay from './HowToPlay';
import Level0 from './Assets/Scenes/Level0';
import Level1, { Dialogs } from './Assets/Scenes/Level1';
import Level2 from './Assets/Scenes/Level2';
import Images from './Assets/Images/Item';
import { getRandomArbitrary } from '../Helpers/math.helpers';
import {
  backgroundMusic,
  toiletFlush,
  backToMenu,
  howToPlay,
  meetCharacters,
  playGame,
} from './Assets/Sounds';
import './Game.css';

let toiletRandomize;

function getNextToiletFlushPlay() {
  return setInterval(() => {
    clearInterval(toiletRandomize);
    toiletFlush.play();
    toiletRandomize = getNextToiletFlushPlay();
  }, getRandomArbitrary(15000, 30000));
}

const LEVELS = [new Level0(), new Level1(), new Level2()];
const INITIAL_LEVEL = 0;

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.askQuestion = this.askQuestion.bind(this);
    this.questionComplete = this.questionComplete.bind(this);
    this.showActionDialog = this.showActionDialog.bind(this);
    this.actionDialogComplete = this.actionDialogComplete.bind(this);
    this.onClickMainMenu = this.onClickMainMenu.bind(this);
    this.onClickPlay = this.onClickPlay.bind(this);
    this.onClickMeetGobi = this.onClickMeetGobi.bind(this);
    this.onClickHowToPlay = this.onClickHowToPlay.bind(this);
    this.reset = this.reset.bind(this);
    this.scene = LEVELS[INITIAL_LEVEL].scene;
    this.player = new Player(0, 0);
    this.controller = new Controller(this.player, this.scene, this.update, this);
    this.state = {
      game: this.scene.getBoard(this.getOverlay()),
      fullscreen: false,
      showQuestionModal: false,
      modalContent: undefined,
      showActionDialog: false,
      dialogAction: {},
      currentLevel: INITIAL_LEVEL,
      showMeetGobi: false,
      showHowToPlay: false,
      hideMainStage: false,
      showQuitModal: false,
      animate: true,
      animationInterval: undefined,
    };
    this.onTouchEvent = this.onTouchEvent.bind(this);
    this.initAnimationTimer = this.initAnimationTimer.bind(this);
  }

  componentDidMount() {
    toiletRandomize = getNextToiletFlushPlay();
    backgroundMusic.play();
    this.initAnimationTimer();
    this.update();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.fullscreen !== prevState.fullscreen) {
      this.controller.updatePlayer(this.player.location.x, this.player.location.y);
      this.update();
    }
  }

  componentWillUnmount() {
    const { animationInterval } = this.state;
    clearInterval(animationInterval);
    this.setState({
      animationInterval,
    });
  }


  onClickMainMenu() {
    backToMenu.play();
    this.setState({
      showMeetGobi: false,
      showHowToPlay: false,
    }, this.update);
  }

  onClickPlay() {
    playGame.play();
    this.setState({
      hideMainStage: true,
    }, this.update);
    this.showActionDialog({
      type: 'START_GAME',
      ...Dialogs.START_GAME,
    });
  }

  onClickMeetGobi() {
    meetCharacters.play();
    this.setState({
      showMeetGobi: true,
    }, this.update);
  }

  onClickHowToPlay() {
    howToPlay.play();
    this.setState({
      showHowToPlay: true,
    }, this.update);
  }

  onTouchEvent(direction) {
    this.controller.handleTouchEvent(direction);
  }

  getOverlay() {
    if (this.state) {
      const {
        currentLevel,
        showMeetGobi,
        showHowToPlay,
        animate,
      } = this.state;
      return (
        <>
          {currentLevel === 0 && !showMeetGobi
            && !showHowToPlay &&
            <MainMenu
              onClickPlay={this.onClickPlay}
              onClickMeetGobi={this.onClickMeetGobi}
              onClickHowToPlay={this.onClickHowToPlay}
            />}
          {showMeetGobi &&
            <MeetGobi />}
          {showHowToPlay &&
            <HowToPlay />}
          {(showMeetGobi || showHowToPlay) &&
            <Button
              color="black"
              size="massive"
              onClick={this.onClickMainMenu}
              inverted
              style={{
                position: 'relative',
                zIndex: 25,
                width: 217,
                height: 74,
                fontSize: 22,
                backgroundImage: `url(${showMeetGobi
                  ? Images.mainMenuButton
                  : Images.mainMenuPurple})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                top: -730,
                left: 700,
              }}
            />}
          <Transition visible={animate} animation="pulse" duration={1000} transitionOnMount>
            <img
              src={Images.theSun}
              alt="Sun"
              style={{
                position: 'absolute',
                top: -125,
                left: -100,
                width: 300,
              }}
            />
          </Transition>
          {currentLevel > 0 &&
            <>
              <Button
                circular
                style={{
                  position: 'relative',
                  height: 50,
                  width: 50,
                  zIndex: 25,
                  top: -630,
                  float: 'right',
                  right: 10,
                  border: 'none',
                  backgroundColor: 'transparent',
                  backgroundImage: `url(${Images.closeButton})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
                onClick={() => this.setState({ showQuitModal: true })}
              />
              <div
                id="game-player-scrore-overlay"
                style={{
                  position: 'relative',
                  fontSize: 35,
                  zIndex: 20,
                  paddingLeft: 15,
                  right: 0,
                  color: 'white',
                  background: 'linear-gradient(to right, rgba(30,87,153,.5) 1%,rgba(96,25,158,.5) 51%,rgba(82,209,18,.5) 100%)',
                  width: 200,
                  textAlign: 'center',
                  borderRadius: 180,
                }}
              >
                <Transition animation="pulse" duration={250} visible={this.player.scoreChanged}>
                  <p style={{ paddingRight: 15, color: 'rgba(255, 255, 255, .85)', textShadow: '1px 1px 4px rgba(0, 5, 0, .5)' }}>
                    Score: {this.player.score}
                  </p>
                </Transition>
              </div>
            </>}
          </>
      );
    }
    return <></>;
  }

  initAnimationTimer() {
    const intervalTime = getRandomArbitrary(5000, 10000);
    const animationInterval = setInterval(() => {
      this.setState({
        animate: !this.state.animate,
      });
    }, intervalTime);
    this.setState({
      animationInterval,
    });
  }

  update() {
    const { hideMainStage } = this.state;
    this.setState({
      game: this.scene.getBoard(
        this.getOverlay(),
        hideMainStage,
        direction => this.onTouchEvent(direction),
      ),
    });
  }

  askQuestion(question) {
    this.setState({
      showQuestionModal: true,
      modalContent: question,
    });
  }

  questionComplete(result) {
    const { modalContent } = this.state;
    if (result) {
      this.controller.updateScoreAndScene(this.player.getScore() + modalContent.REWARD_POINTS);
    }
    this.setState({
      showQuestionModal: false,
      modalContent: undefined,
    });
  }

  showActionDialog(action) {
    const shouldHideMainStage = ['ADVANCE', 'END_GAME'].includes(action.type);
    this.setState({
      showActionDialog: true,
      dialogAction: action,
      [shouldHideMainStage ?
        'hideMainStage'
        : '']: shouldHideMainStage ? true : undefined,
    });
  }

  actionDialogComplete(action) {
    if (action.type === 'ADVANCE') {
      delete this.controller;
      LEVELS[this.state.currentLevel + 1].reset();
      this.scene = LEVELS[this.state.currentLevel + 1].scene;
      this.scene.fullscreen = this.state.fullscreen;
      this.controller = new Controller(this.player, this.scene, this.update, this);
      this.setState({
        showActionDialog: false,
        dialogAction: undefined,
        currentLevel: this.state.currentLevel + 1,
        hideMainStage: false,
      });
      this.update();
    } else if (action.type === 'END_GAME') {
      this.reset();
      this.update();
    } else if (action.type === 'START_GAME') {
      this.setState({
        showActionDialog: false,
        dialogAction: undefined,
      }, () => this.showActionDialog({ type: 'LEARN_1', ...Dialogs.LEARN_1 }));
    } else if (action.type === 'LEARN_1') {
      this.setState({
        showActionDialog: false,
        dialogAction: undefined,
      }, () => this.showActionDialog({ type: 'LEARN_2', ...Dialogs.LEARN_2 }));
    } else if (action.type === 'LEARN_2') {
      this.setState({
        showActionDialog: false,
        dialogAction: undefined,
      }, () => this.showActionDialog({ type: 'LEARN_3', ...Dialogs.LEARN_3 }));
    } else if (action.type === 'LEARN_3') {
      delete this.controller;
      LEVELS[1].reset();
      this.scene = LEVELS[1].scene;
      this.scene.fullscreen = this.state.fullscreen;
      this.controller = new Controller(this.player, this.scene, this.update, this);
      this.setState({
        hideMainStage: false,
        showActionDialog: false,
        dialogAction: undefined,
        currentLevel: 1,
      });
      this.update();
    }
  }

  reset() {
    LEVELS[INITIAL_LEVEL].reset();
    this.scene = LEVELS[INITIAL_LEVEL].scene;
    this.scene.fullscreen = this.state.fullscreen;
    delete this.player;
    this.player = new Player(0, 0);
    delete this.controller;
    this.controller = new Controller(this.player, this.scene, this.update, this);
    this.setState({
      game: this.scene.getBoard(this.getOverlay()),
      showQuestionModal: false,
      hideMainStage: false,
      modalContent: undefined,
      showActionDialog: false,
      dialogAction: undefined,
      currentLevel: INITIAL_LEVEL,
      showMeetGobi: false,
      showHowToPlay: false,
      showQuitModal: false,
    }, this.update);
  }

  toggleFullscreen() {
    const { fullscreen } = this.state;
    if (!fullscreen) {
      try {
        document.documentElement.requestFullscreen();
      } catch {
        //
      }
      try {
        document.documentElement.webkitRequestFullScreen();
      } catch {
        //
      }
      this.scene.goFullscreen();
    } else {
      try {
        document.documentElement.exitFullscreen();
      } catch {
        //
      }
      try {
        document.webkitExitFullscreen();
      } catch {
        //
      }
      this.scene.exitFullscreen();
    }
    this.setState({
      fullscreen: !fullscreen,
    });
    setTimeout(() => {
      this.update();
    }, 200);
  }

  render() {
    const {
      game,
      showQuestionModal,
      modalContent,
      showActionDialog,
      dialogAction,
      fullscreen,
      showQuitModal,
    } = this.state;
    return (
      <>
        <Button
          content={this.scene.fullscreen
            ? 'Exit Fullscreen'
            : 'Fullscreen'}
          onClick={this.toggleFullscreen}
          style={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            color: 'skyblue',
            float: 'right',
          }}
        />
        <Button
          content="Reset"
          onClick={this.reset}
          style={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            color: 'skyblue',
            float: 'right',
          }}
        />
        {game}
        {showQuestionModal &&
          <QuestionModal
            question={modalContent}
            questionComplete={this.questionComplete}
            isFullscreen={fullscreen}
          />}
        {showActionDialog &&
          <ActionDialog
            action={dialogAction}
            actionDialogComplete={this.actionDialogComplete}
            isFullscreen={fullscreen}
          />}
        <Confirm
          open={showQuitModal}
          header="Exit Game"
          content="Are you sure you want to exit the game?"
          confirmButton="Yes"
          cancelButton="No"
          size="tiny"
          style={{
            top: fullscreen
              ? '30%'
              : '15%',
            left: '35%',
            paddingTop: 0,
            flexDirection: 'column',
          }}
          onCancel={() => this.setState({ showQuitModal: false })}
          onConfirm={this.reset}
        />
      </>
    );
  }
}

export default Game;
