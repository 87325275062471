import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Collectible from './Collectible';
import { getRandomArbitrary } from '../../../Helpers/math.helpers';
import dropletImage from '../../Assets/Images/Item/droplet.png';

class Droplet extends Component {
  constructor(props) {
    super(props);
    const {
      x,
      y,
      points,
    } = props;
    const collectible = new Collectible(x, y, dropletImage, points);
    this.state = {
      collectible,
      animate: true,
      animationInterval: undefined,
    };
    this.initAnimationTimer = this.initAnimationTimer.bind(this);
  }

  componentDidMount() {
    this.initAnimationTimer();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.collectible !== this.state.collectible ||
      this.state.animate !== nextState.animate;
  }

  componentWillUnmount() {
    const { animationInterval } = this.state;
    clearInterval(animationInterval);
    this.setState({
      animationInterval,
    });
  }

  initAnimationTimer() {
    const intervalTime = getRandomArbitrary(5000, 10000);
    const animationInterval = setInterval(() => {
      this.setState({
        animate: !this.state.animate,
      });
    }, intervalTime);
    this.setState({
      animationInterval,
    });
  }

  render() {
    const { collectible, animate } = this.state;
    return (
      <Fragment>
        {collectible.getRenderObject(animate)}
      </Fragment>
    );
  }
}

Droplet.defaultProps = {
  x: undefined,
  y: undefined,
  points: 0,
};

Droplet.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  points: PropTypes.number,
};

export default Droplet;
