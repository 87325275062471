import Characters from '../Images/Characters';
import Images from '../Images/Item';

export const Questions = {
  ENEMY1: {
    NAME: '',
    STATEMENT: 'Fats, Oil, and Grease (FOG) when cooled can clog and overflow the sewer system.',
    QUESTION: 'What is the best way to dispose of Fats, Oils, and Grease (FOG)?',
    ANSWER_OPTIONS: [
      'Down the garbage disposal',
      'Flush down the toilet',
      'Into a trash can',
    ],
    CORRECT_ANSWER_INDEX: 2,
    REWARD_POINTS: 20,
    CORRECT_REMARK: 'Correct! The best way to dispose of FOG is to mix them with absorbent materials like paper towels, coffee grounds, or kitty litter and place it in the trash.',
    INCORRECT_REMARK: 'Sorry, wrong answer. The best way to dispose of FOG is to mix them with absorbent materials like paper towels, coffee grounds, or kitty litter and place it in the trash.',
    ENEMY_IMAGE: Characters.grease,
    CORRECT_ANSWER_IMAGE: Characters.greaseCorrect,
    WRONG_ANSWER_IMAGE: Characters.greaseWrong,
  },
  ENEMY2: {
    NAME: '',
    STATEMENT: 'Flushable wipes, cotton pads and swabs, and other personal hygiene products can cause major blockages in the sewer system if not disposed properly.',
    QUESTION: 'Can you flush ‘flushable wipes’?',
    ANSWER_OPTIONS: [
      'Yes',
      'No',
    ],
    CORRECT_ANSWER_INDEX: 1,
    REWARD_POINTS: 20,
    CORRECT_REMARK: 'Correct! You should not flush flushable wipes. “Flushable” wipes can cause major blockages in the sewer system. Throw flushable wipes, cotton pads and swabs, and other personal hygiene products into a trash can.',
    INCORRECT_REMARK: 'Sorry, wrong answer. You should not flush flushable wipes. “Flushable” wipes can cause major blockages in the sewer system. Throw flushable wipes, cotton pads and swabs, and other personal hygiene products into a trash can.',
    ENEMY_IMAGE: Characters.wetWipe,
    CORRECT_ANSWER_IMAGE: Characters.wetWipeCorrect,
    WRONG_ANSWER_IMAGE: Characters.wetWipeWrong,
    IMAGE_LEFT: 190,
    IMAGE_TOP: 275,
  },
};

export const Dialogs = {
  START_GAME: {
    NAME: 'Welcome to Your A-Maze-Ing Adventure!',
    IMAGE: Characters.patrickSmallSmileBlink,
    MESSAGE: 'Hello Gobi, welcome to your A-Maze-Ing Adventure! My name is Patrick the Poo, and today you will learn all about wastewater and recycled water! But first, you have to learn about where Eastern Municipal Water District gets their water supply and how recycled water plays an important role in securing a reliable supply of water.',
  },
  LEARN_1: {
    NAME: 'About EMWD’s Water Supply',
    IMAGE: Characters.patrickHappyBlink,
    MESSAGE: 'Eastern Municipal Water District’s (EMWD) primary role is to provide you and your family with a safe, reliable, and clean source of water to drink, irrigate landscapes, and for recreational uses.',
  },
  LEARN_2: {
    NAME: 'About EMWD’s Water Sources',
    IMAGE: Characters.patrickSmallSmileLook,
    SUPP_IMAGE: Images.caMap,
    MESSAGE: 'EMWD gets most of its water supply from Northern California and the Colorado River Aqueduct, more than a third is highly treated recycled water, and the remaining comes from groundwater sources (water held beneath Earth’s surface).',
  },
  LEARN_3: {
    NAME: '',
    IMAGE: Characters.patrickHappyBlink,
    MESSAGE: 'Now you know where EMWD gets its water supply, but do you know where it goes after it’s used? Let’s go through the pipe maze to find out!',
  },
  PORTAL: {
    NAME: '',
    IMAGE: Characters.patrickHappyBlink,
    MESSAGE: 'We’ve reached the Water Reclamation Facility where all the wastewater ends up. More than 45 million gallons of wastewater is treated daily by 4 Water Reclamation Facilities. Let’s continue to find out more!',
  },
};
