import React from 'react';
import { Image, Transition } from 'semantic-ui-react';
import Characters from './Assets/Images/Characters';

const MeetGobi = () => (
  <Transition animation="fade right" duratio={500} transitionOnMount>
    <div
      id="emwd-meet-chars"
      style={{
        zIndex: 24,
        width: 960,
        height: 640,
        backgroundColor: 'rgb(0, 0, 0, 0)',
        position: 'relative',
        display: 'inline-block',
        top: -640,
        left: 0,
      }}
    >
      <Image
        src={Characters.gobiSmallSmileBlink}
        alt="Gobi. (Main character)"
        style={{
          display: 'block',
          position: 'relative',
          width: 465,
          top: 290,
          transform: 'scale(1.25,1.25)',
          left: -150,
        }}
      />
      <h1
        style={{
          display: 'block',
          color: 'white',
          position: 'relative',
          fontSize: 45,
          textAlign: 'left',
          whiteSpace: 'pre',
          top: 15,
          left: 150,
        }}
      >
        Gobi
      </h1>
      <p
        style={{
          position: 'relative',
          color: 'white',
          fontSize: 20,
          width: 285,
          top: 15,
          left: 150,
        }}
      >
        {`Gobi the water drop was created by local
          Temecula students, Lucas Pfeiffer and Kayleigh
          Frederick, for the storybook `}
        <a href="https://www.emwd.org/sites/main/files/file-attachments/gobi_adventure.pdf" target="_blank" rel="noopener noreferrer">
          {"Gobi's Adventure."}
        </a>
      </p>
      <Image
        src={Characters.patrickSmallSmileLook}
        alt="Patrick the Poo"
        style={{
          display: 'block',
          position: 'relative',
          width: 270,
          top: -155,
          transform: 'scale(-1.5,1.5)',
          left: 362,
        }}
      />
      <h1
        style={{
          display: 'block',
          color: 'white',
          position: 'relative',
          fontSize: 45,
          textAlign: 'left',
          whiteSpace: 'pre',
          top: -385,
          left: 590,
        }}
      >
        Patrick the Poo
      </h1>
      <p
        style={{
          position: 'relative',
          color: 'white',
          fontSize: 20,
          width: 285,
          top: -390,
          left: 590,
        }}
      >
        {'Patrick the Poo was created for the educational video '}
        <a href="https://www.youtube.com/watch?v=OCgY77yLqOs&t=38s)" target="_blank" rel="noopener noreferrer">Where does it Go?</a>
        {' This video takes you on a journey through Sewer Land to learn about the wastewater treatment process.'}
      </p>
    </div>
  </Transition>
);

export default MeetGobi;
