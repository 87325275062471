import React from 'react';
import Scene from '../../Entities/Board/Scene';
import Droplet from '../../Entities/Item/Droplet';
import { Questions, Dialogs } from '../ModalText/Level2';
import Savvy from '../../Entities/Enemies/Savvy';
import Aguat from '../../Entities/Enemies/Aguat';
import Portal from '../../Entities/Elements/Portal';
import { PipeImages } from '../../Entities/Board/Path/Path';
import Images from '../../Assets/Images/Item';

const Color = {
  Brown: 'transparent',
  Purple: 'rgba(148,43, 220, .27)',
};

class Level2 {
  constructor() {
    this.reset();
  }

  reset() {
    this.scene = undefined;
    this.scene = new Scene(30, 20);
    this.scene.setBackground(Images.level2);
    // this.scene.showTilePosition(true);
    // Path

    this.scene.addPathTile(3, 9, Color.Brown, PipeImages.end90, Color.Purple);
    this.scene.addPathTile(3, 10, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(3, 11, Color.Brown, PipeImages.T270, Color.Purple);
    this.scene.addPathTile(3, 12, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(3, 13, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(3, 14, Color.Brown, PipeImages.T270, Color.Purple);
    this.scene.addPathTile(3, 15, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(3, 16, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(3, 17, Color.Brown, PipeImages.T270, Color.Purple);
    this.scene.addPathTile(3, 18, Color.Brown, PipeImages.corner90, Color.Purple);
    this.scene.addPathTile(2, 18, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(1, 18, Color.Brown, PipeImages.corner180, Color.Purple);
    this.scene.addPathTile(1, 17, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(1, 16, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(5, 17, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(4, 17, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(6, 17, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(7, 17, Color.Brown, PipeImages.T90, Color.Purple);

    this.scene.addPathTile(4, 11, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(5, 11, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(6, 11, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(6, 12, Color.Brown, PipeImages.T270, Color.Purple);
    this.scene.addPathTile(6, 13, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(6, 14, Color.Brown, PipeImages.corner90, Color.Purple);
    this.scene.addPathTile(5, 14, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(4, 14, Color.Brown, PipeImages.straight, Color.Purple);

    this.scene.addPathTile(1, 15, Color.Brown, PipeImages.end90, Color.Purple);
    this.scene.addPathTile(7, 12, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(8, 12, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(9, 12, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(9, 13, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(9, 14, Color.Brown, PipeImages.T270, Color.Purple);
    this.scene.addPathTile(9, 15, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(9, 16, Color.Brown, PipeImages.T180, Color.Purple);
    this.scene.addPathTile(8, 16, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(7, 16, Color.Brown, PipeImages.corner270, Color.Purple);
    this.scene.addPathTile(7, 18, Color.Brown, PipeImages.end270, Color.Purple);

    this.scene.addPathTile(27, 11, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(16, 11, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(12, 10, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(15, 17, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(19, 15, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(19, 16, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(19, 17, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(19, 18, Color.Brown, PipeImages.T180, Color.Purple);
    this.scene.addPathTile(11, 14, Color.Brown, PipeImages.T, Color.Purple);
    this.scene.addPathTile(12, 14, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(13, 14, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(10, 16, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(10, 17, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(10, 18, Color.Brown, PipeImages.corner180, Color.Purple);
    this.scene.addPathTile(11, 18, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(12, 18, Color.Brown, PipeImages.end180, Color.Purple);
    this.scene.addPathTile(24, 15, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(25, 15, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(26, 15, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(26, 16, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(26, 18, Color.Brown, PipeImages.end270, Color.Purple);
    this.scene.addPathTile(19, 14, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(12, 16, Color.Brown, PipeImages.end, Color.Purple);
    this.scene.addPathTile(13, 16, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(14, 16, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(15, 16, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(11, 10, Color.Brown, PipeImages.corner270, Color.Purple);
    this.scene.addPathTile(13, 10, Color.Brown, PipeImages.T90, Color.Purple);
    this.scene.addPathTile(13, 9, Color.Brown, PipeImages.end90, Color.Purple);
    this.scene.addPathTile(16, 10, Color.Brown, PipeImages.corner270, Color.Purple);
    this.scene.addPathTile(16, 12, Color.Brown, PipeImages.corner180, Color.Purple);
    this.scene.addPathTile(15, 18, Color.Brown, PipeImages.corner180, Color.Purple);
    this.scene.addPathTile(21, 12, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(21, 18, Color.Brown, PipeImages.corner90, Color.Purple);
    this.scene.addPathTile(23, 10, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(23, 15, Color.Brown, PipeImages.T180, Color.Purple);
    this.scene.addPathTile(24, 17, Color.Brown, PipeImages.end, Color.Purple);
    this.scene.addPathTile(28, 17, Color.Brown, PipeImages.corner90, Color.Purple);
    this.scene.addPathTile(28, 11, Color.Brown, PipeImages.corner, Color.Purple);
    this.scene.addPathTile(26, 9, Color.Brown, PipeImages.end90, Color.Purple);
    this.scene.addPathTile(26, 10, Color.Brown, PipeImages.straight90, Color.Purple);
    this.scene.addPathTile(26, 11, Color.Brown, PipeImages.corner180, Color.Purple);
    this.scene.addPathTile(22, 15, Color.Brown, PipeImages.straight, Color.Purple);
    this.scene.addPathTile(21, 15, Color.Brown, PipeImages.T270, Color.Purple);

    for (let i = 11; i < 14; i += 1) {
      this.scene.addPathTile(11, i, Color.Brown, PipeImages.straight90, Color.Purple);
    }
    for (let i = 11; i < 14; i += 1) {
      this.scene.addPathTile(13, i, Color.Brown, PipeImages.straight90, Color.Purple);
    }
    for (let i = 10; i < 19; i += 1) {
      this.scene.addPathTile(i, 14, Color.Brown, [11, 13].includes(i)
        ? PipeImages.T180 : PipeImages.straight, Color.Purple);
    }
    for (let i = 17; i < 23; i += 1) {
      this.scene.addPathTile(i, 10, Color.Brown, PipeImages.straight, Color.Purple);
    }
    for (let i = 17; i < 21; i += 1) {
      this.scene.addPathTile(i, 12, Color.Brown, PipeImages.straight, Color.Purple);
    }
    for (let i = 16; i < 21; i += 1) {
      if (i !== 19) {
        this.scene.addPathTile(i, 18, Color.Brown, PipeImages.straight, Color.Purple);
      }
    }
    for (let i = 13; i < 18; i += 1) {
      if (i !== 15) {
        this.scene.addPathTile(21, i, Color.Brown, PipeImages.straight90, Color.Purple);
      }
    }
    for (let i = 11; i < 15; i += 1) {
      this.scene.addPathTile(23, i, Color.Brown, PipeImages.straight90, Color.Purple);
    }
    for (let i = 25; i < 28; i += 1) {
      this.scene.addPathTile(i, 17, Color.Brown, i === 26 ?
        PipeImages.cross : PipeImages.straight, Color.Purple);
    }
    for (let i = 12; i < 17; i += 1) {
      this.scene.addPathTile(28, i, Color.Brown, PipeImages.straight90, Color.Purple);
    }

    this.scene.setStartTile(3, 9);
    this.scene.addCollectible(<Droplet {...{ x: 8, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 9, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 8, y: 16, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 7, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 10, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 11, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 12, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 12, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 17, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 15, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 17, y: 14, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 19, y: 15, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 21, y: 16, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 21, y: 13, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 20, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 12, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 15, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 18, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 20, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 16, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 19, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 21, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 23, y: 11, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 24, y: 15, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 25, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 27, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 28, y: 15, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 28, y: 12, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 26, y: 10, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 13, y: 16, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 5, y: 17, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 2, y: 18, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 3, y: 15, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 1, y: 16, points: 5 }} />);
    this.scene.addCollectible(<Droplet {...{ x: 4, y: 11, points: 5 }} />);

    this.scene.addEnemy(<Savvy {...{ x: 14, y: 14, action: { type: 'POPUP', ...Questions.ENEMY1 } }} />);
    this.scene.addEnemy(<Aguat {...{ x: 26, y: 15, action: { type: 'POPUP', ...Questions.ENEMY2 } }} />);

    this.scene.addPortal(new Portal(26, 9, { type: 'END_GAME', getDialog: Dialogs.END_GAME }));
  }
}

export default Level2;
