import React from 'react';
import Characters from '../Images/Characters';

export const Questions = {
  ENEMY1: {
    NAME: '',
    STATEMENT: 'EMWD uses advanced technology based on the natural water recycling process to clean the water. This treatment process is called Tertiary Treatment, which removes bacteria, viruses, and any solids.',
    QUESTION: 'What percentage of wastewater is recycled? ',
    ANSWER_OPTIONS: [
      '25%',
      '75%',
      '100%',
    ],
    CORRECT_ANSWER_INDEX: 2,
    REWARD_POINTS: 20,
    CORRECT_REMARK: 'Correct! 100% of EMWD\'s recycled water is cleaned and used to irrigate landscapes, agriculture, sports fields, golf courses, parks, schools, and more!',
    INCORRECT_REMARK: 'Sorry, wrong answer. 100% of EMWD\'s recycled water is cleaned and used to irrigate landscapes, agriculture, sports fields, golf courses, parks, schools, and more!',
    ENEMY_IMAGE: Characters.savvy,
    CORRECT_ANSWER_IMAGE: Characters.savvyHappyBlink,
    WRONG_ANSWER_IMAGE: Characters.savvySadLook,
  },
  ENEMY2: {
    NAME: '',
    STATEMENT: 'Did you know: EMWD delivers recycled water through a special pipeline system?',
    QUESTION: 'Recycled water travels in what colored pipes?',
    ANSWER_OPTIONS: [
      'Blue',
      'Purple',
      'White',
    ],
    CORRECT_ANSWER_INDEX: 1,
    REWARD_POINTS: 20,
    CORRECT_REMARK: 'Correct! Recycled water flows through purple pipes. Anytime you see a purple pipe or sprinkler you will know the water in the pipeline is recycled!',
    INCORRECT_REMARK: 'Sorry, wrong answer. Recycled water flows through purple pipes. Anytime you see a purple pipe or sprinkler you will know the water in the pipeline is recycled!',
    ENEMY_IMAGE: Characters.aguat,
    CORRECT_ANSWER_IMAGE: Characters.aguatHappyBlink,
    WRONG_ANSWER_IMAGE: Characters.aguatSadLook,
  },
};

export const Dialogs = {
  PORTAL: {
    NAME: "You're Savvy about Recycled Water!",
    IMAGE: Characters.patrickSmallSmileLook,
    MESSAGE: 'We’ve reached the Water Reclamation Facility where all the wastewater ends up. More than 45 million gallons of wastewater is treated daily by 4 Water Reclamation Facilities. Let’s continue to find out more!',
  },
  END_GAME: (score) => {
    let message = '';
    let name = '';
    if (score <= 190) {
      message = <>{"You're a Sewer Smart Expert! To learn more about sewer safety, visit. "}<a target="_blank" rel="noopener noreferrer" href="http://www.emwd.org/SewerSmart">Click Here to See Now</a></>;
      name = 'You\'re a Sewer Smart Expert!';
    } else if (score <= 325) {
      message = <>{"You're Savvy about Recycled Water! To learn more about recycled water, check out the storybook Professor Purrkis Ponders Purple Pipes. "}<a target="_blank" rel="noopener noreferrer" href="http://emwd.ddsandbox.net/sites/main/files/file-attachments/081016ppponderspurplepipes.pdf">Click Here to Read Now</a></>;
      name = "You're Savvy about Recycled Water!";
    } else if (score <= 455) {
      message = <>{"You're a Water Purification Hero! To learn more about purified water, check out the storybook Gobi, Aguat, and Savvy Get Squeaky Clean Again. "}<a target="_blank" rel="noopener noreferrer" href="https://www.emwd.org/sites/main/files/file-attachments/gobiaguatandsavvygetsqueak.pdf">Click Here to Read Now</a></>;
      name = 'You\'re a Water Purification Hero!';
    }
    return {
      NAME: name,
      IMAGE: Characters.patrickHappyBlink,
      MESSAGE: message,
      SCORE: score,
      LINK: '',
      type: 'END_GAME',
    };
  },
};

