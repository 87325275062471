/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  TransitionablePortal,
  Button,
  Transition,
} from 'semantic-ui-react';
import Images from '../../Assets/Images/Item';
import { meetCharacters } from '../../Assets/Sounds';

const isIE = navigator.userAgent.indexOf('MSIE') !== -1
|| navigator.appVersion.indexOf('Trident/') > -1;

const isiPad = navigator.userAgent.match(/iPad/i) != null;

class ActionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delayButton: true,
      open: true,
    };
    this.buttonClicked = this.buttonClicked.bind(this);
    this.delayButton = this.delayButton.bind(this);
    this.delayButton();
  }

  delayButton() {
    setTimeout(() => {
      this.setState({
        delayButton: false,
      });
      if (this.actionButton) {
        this.actionButton.focus();
      }
    }, 5000);
  }

  buttonClicked() {
    const { action, actionDialogComplete } = this.props;
    meetCharacters.play();
    this.setState({
      open: false,
    }, () => {
      setTimeout(() =>
        actionDialogComplete(action), 505);
    });
  }

  render() {
    const { action } = this.props;
    const {
      delayButton,
      open,
    } = this.state;
    return (
      <TransitionablePortal
        open={open}
        transition={{ animation: 'zoom', duration: 500 }}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        closeOnTriggerClick={false}
        closeOnDocumentClick={false}
        closeOnTriggerMouseLeave={false}
      >
        <Modal
          onClick={() => { this.actionButton.focus(); }}
          open
          centered
          style={{
            top: '3%',
            left: 0,
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            maxHeight: '100vh',
          }}
          basic
        >
          <Modal.Header
            style={{
              fontSize: 30,
              border: 'none',
              display: 'block',
              padding: 0,
              transform: window.innerWidth < 500 || window.innerHeight < 600
              ? 'scale(0.5)'
              : window.innerWidth < 1000 || window.innerHeight < 900
                ? (isiPad ? 'scale(0.7)' : 'scale(0.8)')
                : undefined,
            }}
          >
            {action.SCORE &&
              <>
                Total Score: {action.SCORE} <br />
              </>}
            {action.NAME}
          </Modal.Header>
          <Modal.Content
            style={{
              padding: 0,
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              transform: window.innerWidth < 500 || window.innerHeight < 600
              ? 'scale(0.5)'
              : window.innerWidth < 1000 || window.innerHeight < 900
                ? 'scale(0.8)'
                : undefined,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${Images.speechBubble})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  textAlign: 'center',
                  height: 600,
                  width: 600,
                  padding: '',
                  margin: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                  ...(isiPad
                    ? {
                      position: 'relative',
                      top: -90,
                    }
                    : {}),
                }}
              >
                <p style={{
                    color: 'black',
                    fontSize: 19,
                    width: '70%',
                    height: '45%',
                    textAlign: 'center',
                    paddingBottom: '10%',
                    ...(!isIE ? {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '10%',
                    } :
                    {}),
                  }}
                >
                  {action &&
                  <>
                    {action.MESSAGE}
                  </>}
                </p>
              </div>
              {action.SUPP_IMAGE &&
                <Transition
                  visible
                  animation="bounce"
                  duration={1000}
                  transitionOnMount
                >
                  <img
                    src={action.SUPP_IMAGE}
                    alt={action.NAME}
                    style={{
                      width: 'auto',
                      height: action.SUPP_IMAGE_HEIGHT || 500,
                      marginTop: 30,
                    }}
                  />
                </Transition>}
            </div>
            {action &&
              <Transition
                visible
                animation="bounce"
                duration={1000}
                transitionOnMount
              >
                <img
                  src={action.IMAGE}
                  alt={action.NAME}
                  width={256}
                  height={256}
                  style={{
                    width: 'auto',
                    height: 300,
                    position: 'relative',
                    left: action.SUPP_IMAGE ? -235 : 135,
                    bottom: isiPad ? -35 : 150,
                  }}
                />
              </Transition>}
            <Button
              disabled={delayButton}
              onClick={this.buttonClicked}
              ref={(r) => { this.actionButton = r; }}
              onKeyDown={(e) => {
                e.preventDefault();
                if (e.keyCode === 13 || e.keyCode === 39) {
                  this.buttonClicked();
                }
              }}
              size="massive"
              primary
              style={{
                width: 217,
                height: 75,
                backgroundImage: `url(${action.type === 'END_GAME'
                  ? Images.mainMenuButton
                  : Images.continueArrow})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'transparent',
                backgroundSize: 'contain',
                position: 'relative',
                left: 450,
                bottom: 150,
                zIndex: 1200,
              }}
            />
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

ActionDialog.defaultProps = {
  action: undefined,
  actionDialogComplete: () => {},
  // isFullscreen: false,
};

ActionDialog.propTypes = {
  action: PropTypes.shape({}),
  actionDialogComplete: PropTypes.func,
  // isFullscreen: PropTypes.bool,
};

export default ActionDialog;
