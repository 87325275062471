/* eslint-disable import/first */
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/extensions
import 'reset-css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import bg from './Game/Assets/Images/drops.png';
import titleImage from './Game/Assets/Images/Item/gametitle.png';

import Characters from './Game/Assets/Images/Characters';
import Items from './Game/Assets/Images/Item';
import Audio from './Game/Assets/Sounds';

function createElementFromHTML(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild;
}

function addPreloadLinks() {
  const preloadLinks = [];
  Object.keys(Characters).forEach(i =>
    preloadLinks.push(`<link rel="preload" href="${Characters[i]}" as="image" >`));
  Object.keys(Items).forEach(i =>
    preloadLinks.push(`<link rel="preload" href="${Items[i]}" as="image" >`));
  Object.keys(Audio).forEach(i =>
    preloadLinks.push(`<link rel="preload" href="${Audio[i]}" as="audio" >`));
  preloadLinks.forEach(l =>
    document.querySelector('head').appendChild(createElementFromHTML(l)));
}

addPreloadLinks();

function startGame() {
  ReactDOM.render(<App />, document.getElementById('root'));
}

ReactDOM.render(
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundImage: `url(${bg})`,
      backgroundPositionY: -180,
    }}
  >
    <img src={titleImage} alt="Gobi's Amazing Adventure" style={{ width: 400 }} />
    <button
      id="main-start-button"
      onClick={startGame}
      style={{
        height: 60,
        width: 300,
        marginTop: 25,
        borderRadius: 180,
        backgroundImage: 'linear-gradient(to right, #36d1dc, #5b86e5)',
        color: 'white',
        fontSize: 25,
        border: 'none',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        transition: 'all .5s',
      }}
    >
      Start Game
    </button>
    <style>{`
      #main-start-button:hover {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22) !important;
    }`}
    </style>
  </div>,
  document.getElementById('root'),
);

function preventBehavior(e) {
  e.preventDefault();
}

document.addEventListener('touchmove', preventBehavior, { passive: false });
