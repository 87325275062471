import React from 'react';
import { Transition } from 'semantic-ui-react';
import Item from '../Item/Item';

class Enemy extends Item {
  constructor(x, y, image, action) {
    super(x, y, image, action);
    this.width = 64;
    this.height = 64;
  }

  getRenderObject(animate, animation) {
    return (
      <Transition visible={animate} animation={animation} duration={2500} transitionOnMount>
        <img
          className="emwd-genemy"
          src={this.imagePath}
          width={this.width}
          height={this.height}
          style={{
            height: this.height,
            width: this.width,
            top: this.top || -(this.height / 2),
            left: this.left || -(this.width / 4),
            position: 'absolute',
            margin: 0,
            padding: 0,
            zIndex: 13,
          }}
          alt="Game enemy."
        />
      </Transition>
    );
  }
}

export default Enemy;
