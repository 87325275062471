import Scene from '../../Entities/Board/Scene';
import bg from '../Images/Item/mainmenu.png';

class Level1 {
  constructor() {
    this.reset();
  }

  reset() {
    this.scene = undefined;
    this.scene = new Scene(30, 20);
    this.scene.setBackground(bg);
    // this.scene.showTilePosition(true);
    this.scene.setStartTile(0, 0);
  }
}

export default Level1;
