import pipeEndCap from '../../../Assets/Images/pipes_end.png';
import pipeEndCap90 from '../../../Assets/Images/pipes_end90.png';
import pipeEndCap180 from '../../../Assets/Images/pipes_end180.png';
import pipeEndCap270 from '../../../Assets/Images/pipes_end270.png';

import pipeStraight from '../../../Assets/Images/pipes_straight.png';
import pipeStraight90 from '../../../Assets/Images/pipes_straight90.png';

import pipeCorner from '../../../Assets/Images/pipes_corner.png';
import pipeCorner90 from '../../../Assets/Images/pipes_corner90.png';
import pipeCorner180 from '../../../Assets/Images/pipes_corner180.png';
import pipeCorner270 from '../../../Assets/Images/pipes_corner270.png';

import pipeT from '../../../Assets/Images/pipes-T.png';
import pipeT90 from '../../../Assets/Images/pipes-T90.png';
import pipeT180 from '../../../Assets/Images/pipes-T180.png';
import pipeT270 from '../../../Assets/Images/pipes-T270.png';

import pipeCross from '../../../Assets/Images/pipes_cross.png';

const IMAGES = [
  pipeEndCap,
  pipeEndCap90,
  pipeEndCap180,
  pipeEndCap270,

  pipeStraight,
  pipeStraight90,

  pipeCorner,
  pipeCorner90,
  pipeCorner180,
  pipeCorner270,

  pipeT,
  pipeT90,
  pipeT180,
  pipeT270,

  pipeCross,
];

export const PipeImages = {
  end: 0,
  end90: 1,
  end180: 2,
  end270: 3,

  straight: 4,
  straight90: 5,

  corner: 6,
  corner90: 7,
  corner180: 8,
  corner270: 9,

  T: 10,
  T90: 11,
  T180: 12,
  T270: 13,

  cross: 14,
};

class PathTile {
  constructor(x, y, backgroundColor, backgroundImage, colorOverlay) {
    this.xPosition = x;
    this.yPosition = y;
    this.backgroundColor = backgroundColor;
    this.backgroundImage = IMAGES[backgroundImage];
    this.overlay = colorOverlay;
    this.className = 'emwd-gbtpt';
  }

  setContent(content) {
    this.content = content;
  }
}

class Path {
  constructor() {
    this.tiles = new Map();
  }

  addTile(x, y, backgroundImage, backgroundColor, overlay) {
    const tile = new PathTile(x, y, backgroundColor, backgroundImage, overlay);
    this.tiles.set(`${x}-${y}`, tile);
  }

  setStartTile(x, y) {
    this.tiles.set('start', this.getTile(x, y));
  }

  getStartTile() {
    return this.tiles.get('start');
  }

  getTile(x, y) {
    return this.tiles.get(`${x}-${y}`);
  }

  setTileContent(x, y, content) {
    const tile = this.getTile(x, y);
    if (tile) {
      tile.setContent(content);
      this.tiles.set(`${x}-${y}`, tile);
    }
  }
}

export default Path;
