import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Tile.css';

const TILE_DEFAULT_BORDER_WIDTH = 0;
export const TILE_DEFAULT_DIM = 32 + (TILE_DEFAULT_BORDER_WIDTH * 2);

const getTileClassName = (
  leftEdge,
  rightEdge,
  topEdge,
  bottomEdge,
) => `emwd-gbt${(leftEdge && ' emwd-gbtle') || ''}${(rightEdge && ' emwd-gbtre') || ''}${(topEdge && ' emwd-gbtte') || ''}${(bottomEdge && ' emwd-gbtbe') || ''}`;

class Tile extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.children !== this.props.children ||
      nextProps.backgroundColor !== this.props.backgroundColor ||
      nextProps.showPosition !== this.props.showPosition;
  }

  render() {
    const {
      height,
      width,
      backgroundColor,
      backgroundImage,
      overlay,
      border,
      leftEdge,
      rightEdge,
      topEdge,
      bottomEdge,
      xPosition,
      yPosition,
      className,
      showPosition,
      children,
    } = this.props;

    return (
      <div
        style={{
          width,
          height,
          backgroundColor,
          backgroundImage: backgroundImage && `url(${backgroundImage})`,
          border,
          paddingTop: showPosition ? 5 : undefined,
          paddingLeft: showPosition ? 5 : undefined,
        }}
        {...{
          x: xPosition,
          y: yPosition,
        }}
        className={`${getTileClassName(leftEdge, rightEdge, topEdge, bottomEdge)} ${className || ''}`.trim()}
      >
        {showPosition ? `x:${xPosition}` : undefined}
        {showPosition ? <br /> : undefined}
        {showPosition ? `y:${yPosition}` : undefined}
        <div
          style={{
            backgroundColor: overlay && overlay,
            height: 32,
            width: 32,
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

Tile.defaultProps = {
  height: undefined,
  width: undefined,
  backgroundColor: undefined,
  backgroundImage: undefined,
  border: undefined,
  leftEdge: false,
  rightEdge: false,
  topEdge: false,
  bottomEdge: false,
  xPosition: undefined,
  yPosition: undefined,
  className: undefined,
  showPosition: undefined,
  overlay: undefined,
};

Tile.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  border: PropTypes.string,
  leftEdge: PropTypes.bool,
  rightEdge: PropTypes.bool,
  topEdge: PropTypes.bool,
  bottomEdge: PropTypes.bool,
  xPosition: PropTypes.number,
  yPosition: PropTypes.number,
  className: PropTypes.string,
  showPosition: PropTypes.bool,
  overlay: PropTypes.string,
};

export default Tile;
