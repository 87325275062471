import gobiSmallSmileBlink from './Gobi-Small-Smile-Blink.png';
import patrickSmallSmileBlink from './Poop-Small-Smile-Blink.png';
import patrickSmallSmileLook from './Poop-Small-Smile-Look.png';
import patrickHappyBlink from './Poop-Happy-Blink.png';
import greaseCorrect from './Blob-Happy-Look.png';
import greaseWrong from './Blob-Sad-Look.png';
import grease from './grease.png';
import wetWipe from './wetwipe.png';
import wetWipeCorrect from './Wipe-Happy-Blink.png';
import wetWipeWrong from './Wipe-Sad-Look.png';
import gobiHappyLook from './Gobi-Happy-Look.png';
import savvy from './savvy.png';
import savvySadLook from './Savvy-Sad-Look.png';
import savvyHappyBlink from './Savvy-Happy-Blink.png';
import aguat from './aguat.png';
import aguatSadLook from './Aguat-Sad-Look.png';
import aguatHappyBlink from './Aguat-Happy-Blink.png';

export default {
  gobiSmallSmileBlink,
  patrickSmallSmileBlink,
  patrickSmallSmileLook,
  patrickHappyBlink,
  grease,
  greaseCorrect,
  greaseWrong,
  wetWipe,
  wetWipeCorrect,
  wetWipeWrong,
  gobiHappyLook,
  savvy,
  savvyHappyBlink,
  savvySadLook,
  aguat,
  aguatHappyBlink,
  aguatSadLook,
};
